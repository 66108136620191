<template>
    <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">
        <div class="bg-white rounded-xl shadow-md p-3">
            <div class="text-base font-semibold">Cerita</div>
            <div class="border-2 border-gray-200 mt-3 mb-2"></div>
            <div v-if="storyState.isMoreStory">
                <div class="text-sm" v-html="story"></div>
            </div>
            <div v-else>
                <div class="vtl h-80 overflow-hidden">
                    <div class="text-sm" v-html="story"></div>
                    <div class="w-full h-44 absolute -ml-9 bottom-0 fade-in">
                        <button @click="setMoreStory" class="absolute left-1/3 bottom-5 bg-white underline text-yellow-500">
                            Lihat Selengkapnya
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

//hook vue
import { reactive } from 'vue'

export default {

    props: {
        story: String
    },
    setup() {

        const storyState = reactive({
            isMoreStory: false
        })

        function setMoreStory() {
            storyState.isMoreStory = true
        }

        return {
            storyState,
            setMoreStory
        }
    }
}
</script>

<style>
iframe {
    width: 100%;
}

/* (A) TIMELINE CONTAINER */
.vtl {
    /* (A1) RELATIVE POSITION REQUIRED TO PROPERLY POSITION THE TIMELINE */
    position: relative;

    /* (A2) RESERVE MORE SPACE TO THE LEFT FOR THE TIMELINE */
    /* padding: 0px 10px 0px 30px; */

    /* (A3) OPTIONAL WIDTH RESTRICTION */
    /* max-width: 400px; */
}

.vtl,
.vtl * {
    *box-sizing: border-box;
}

.fade-in {
    background-image: linear-gradient(#FFFFFF00 30%, #FFF 75%);
}

</style>
