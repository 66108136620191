<template>
    <div class="relative z-20 transition-none">
        <div @click="setShareShowed" class="fixed inset-0 bg-gray-700 bg-opacity-75"></div>
        <div class="container mx-auto bottom-0 fixed p-3 sm:w-full md:left-1/3 md:w-4/12 md:rounded-t-xl bg-white transition-opacity">
            <div class="grid grid-cols-9 gap-4">
                <h3 class="col-span-8 py-1.5 w-full">Bagikan program ini</h3>
                <button @click="setShareShowed" class="py-1.5 w-full">
                    <i class="fa fa-times text-yellow-500"></i>
                </button>
            </div>
            <div class="my-5 grid grid-cols-4 gap-4">
                <div v-for="s in socialIcon" :key="s.name">
                    <ShareNetwork
                        :network="s.network"
                        :url="shareUrl"
                        :title="shareTitle"
                    >
                        <div class="flex flex-col">
                            <img :src="s.icon" class="w-8 m-auto"/>
                            <h5 class="mt-2 text-xs text-gray-500 text-center">{{s.name}}</h5>
                        </div>
                    </ShareNetwork>
                </div>
            </div>
            <!-- <button @click="setShareShowed" class="bg-yellow-500 w-full py-1.5 rounded-md">
                <i class="fa fa-share-alt text-white"></i>
            </button> -->
        </div>
    </div>
</template>

<script>
    //hook vuex
    import { useStore } from "vuex";

    import { ShareNetwork } from "vue-social-sharing";

    export default {
        props: {
            shareUrl: String,
            shareTitle: String
        },
        components: {
            ShareNetwork
        },

        setup() {

            //store vuex
            const store = useStore();

            function setShareShowed() {
                store.dispatch('campaign/setShareActionSheet', false)
            }

            const socialIcon = [
                { 'name': 'Facebook', 'network': 'facebook', 'icon': 'https://panel.dompetdonasi.id/laravel-core/storage/app/public/facebook.png'},
                { 'name': 'Twitter', 'network': 'twitter', 'icon': 'https://panel.dompetdonasi.id/laravel-core/storage/app/public/twitter.png'},
                // { 'name': 'Instagram', 'network': '', 'icon': 'https://panel.dompetdonasi.id/laravel-core/storage/app/public/instagram.png'},
                { 'name': 'Whatsapp', 'network': 'whatsapp', 'icon': 'https://panel.dompetdonasi.id/laravel-core/storage/app/public/whatsapp.png'},
                { 'name': 'Telegram', 'network': 'telegram', 'icon': 'https://panel.dompetdonasi.id/laravel-core/storage/app/public/telegram.png'},
            ];

            return {
                setShareShowed,
                socialIcon
            }
        }
    }
</script>
