<template>
    <Transition>
        <div v-if="scrollY > 450" class="container mx-auto bottom-0 fixed p-3 sm:w-full md:left-1/3 md:w-4/12 md:rounded-t-xl bg-white transition-opacity">
            <div v-if="maxDate(maximumDate) == true">
                <button
                    class="bg-yellow-500 py-1.5 rounded-md shadow-md text-lg w-full uppercase font-bold focus:outline-none opacity-50 cursor-not-allowed">Donasi
                    Ditutup!</button>
            </div>
            <div v-else>
                <div class="grid grid-cols-6 gap-4">
                    <div class="col-span-5">
                        <div v-if="typeCampaign == campaignType.QURBAN_KAMBING || typeCampaign == campaignType.QURBAN_SAPI || typeCampaign == campaignType.QURBAN_PATUNGAN">
                            <router-link :to="{name: 'qurban.create', params:{
                                    slug: slug, 
                                    nominal: nominal,
                                    qurbanCount: typeCampaign == campaignType.QURBAN_SAPI ? 7 : 1
                                }}">
                                <button
                                    class="bg-yellow-500 py-1.5 rounded-md shadow-md text-lg w-full uppercase font-bold text-white focus:outline-none focus:bg-yellow-600">Qurban
                                    Sekarang!</button>
                            </router-link>
                        </div>
                        <div v-else>
                            <router-link :to="{name: 'donation.create', params:{slug: slug}}">
                                <button
                                    class="bg-yellow-500 py-1.5 rounded-md shadow-md text-lg w-full uppercase font-bold text-white focus:outline-none focus:bg-yellow-600">Donasi
                                    Sekarang!</button>
                            </router-link>
                        </div>
                    </div>
                    <button @click="setShareShowed" class="bg-yellow-500 w-full py-1.5 rounded-md">
                        <i class="fa fa-share-alt text-white"></i>
                    </button>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>

import CampaignTypeEnum from '@/enums/CampaignTypeEnum.js'

//hook vuex
import { useStore } from "vuex";

export default {

    props: {
        scrollY: Number,
        slug: String,
        maximumDate: String,
        typeCampaign: String,
        nominal: String
    },
    setup() {
        const campaignType = CampaignTypeEnum

        //store vuex
        const store = useStore();

        function setShareShowed() {
            store.dispatch('campaign/setShareActionSheet', true)
        }

        return {
            campaignType,
            setShareShowed
        }
    }
}
</script>
<style>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
