<template>
    <div class="container mx-auto mb-16 grid grid-cols-1 p-3 sm:w-full md:w-4/12">
        <div class="bg-white rounded-xl shadow-md p-3">
            <div class="text-base font-semibold">Donasi ({{ donations.length }})</div>
            <div class="border-2 border-gray-200 mt-3 mb-2"></div>
            <div v-for="donation in donations" :key="donation.id">
                <DonatursPrayCell 
                    :id="donation.id"
                    :avatar="donation.donatur.avatar"
                    :name="donation.donatur.name"
                    :created_at="donation.created_at"
                    :amount="donation.amount"
                    :pray="donation.pray"
                    :count_amin="donation.count_amin"
                />
            </div>
        </div>
    </div>
</template>

<script>
import DonatursPrayCell from '@/components/campaign/show/DonatursPrayCell.vue'

export default {
    props: {
        donations: Array
    },
    components: {
        DonatursPrayCell
    }
}
</script>
