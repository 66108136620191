<template>
  <div v-if="!isFirst">
    <div class="line-top"></div>
  </div>
  <div v-if="!isLast">
    <div class="line-bottom"></div>
  </div>
  <div class="event shadow-sm" :style="{
		backgroundColor: withdraw == '1' ? '#FFE2A975' : '#fff'
	}">
    <div class="inline-flex gap-5">
      <div class="w-14 h-14">
        <img :src="image" class="rounded-full shadow bg-white">
      </div>
      <div>
        <p class="title mt-2">{{ title }}</p>
        <span class="date">{{ moment(createdDate, 'YYYY-MM-DDThh:mm:ss.ssssssZ').fromNow() }}</span>
      </div>
    </div>
    
    <div class="m-1">&nbsp;</div>
    <div v-html="desc"></div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    image: String,
    title: String,
    desc: String,
    withdraw: String,
    isFirst: Boolean,
    isLast: Boolean,
    createdDate: String,
  },

  created: function () {
    this.moment = moment;
    this.moment.locale('id');
  }
};
</script>

<style scoped>
/* (C) COSMETICS FOR EVENTS */
div.event {
  padding: 20px 30px;
  background-color: #fff;
  position: relative;
  border-radius: 6px;
  margin-bottom: 10px;
}

/* (D) COSMETICS FOR EVENT DATE & TEXT */
p.title {
  font-size: 1em;
  font-weight: 700;
  /* color: rgba(245, 158, 11, 1); */
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px; /* fallback */
  max-height: 32px; /* fallback */
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.date {
  font-size: 0.7em;
  color: #999;
}
p.txt {
  margin: 10px 0 0 0;
  color: #222;
}

/* (E) EVENT "SPEECH BUBBLE CALLOUT" */

/* (F) CIRCLE ON TIMELINE */
div.event::after {
  /* (F1) "MAGIC CIRCLE" */
  content: "";
  background: rgba(245, 158, 11, 1);
  border: 3px solid #f5f5ff;
  width: 16px;
  height: 16px;
  border-radius: 50%;

  /* (F2) POSITION TO THE LEFT */
  position: absolute;
  top: 30px;
  left: -22px;
}

.line-top {
  /* (B1) VERTICAL LINE */
  content: "";
  width: 2.5px;
  height: 40px;
  background-color: rgba(245, 158, 11, 1);

  /* (B2) POSITION TO THE LEFT */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
}

.line-bottom {
  /* (B1) VERTICAL LINE */
  content: "";
  width: 2.5px;
  background-color: rgba(245, 158, 11, 1);

  /* (B2) POSITION TO THE LEFT */
  position: absolute;
  top: 40px;
  bottom: 0;
  left: 15px;
}
</style>