<template>
    <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">
        <div class="bg-white rounded-xl shadow-md p-3">
            <img class="rounded-md w-full" :src="image">
            <div class="mt-5">
                <p class="text-base font-semibold">{{ title }}</p>
            </div>

            <div v-if="sumDonation.length > 0">
                <div v-for="donation in sumDonation" :key="donation">
                    <div v-if="typeCampaign == campaignType.QURBAN_KAMBING || typeCampaign == campaignType.QURBAN_SAPI || typeCampaign == campaignType.QURBAN_PATUNGAN">
                        <p class="mt-4 text-sm text-gray-500">
                            <span class="font-bold text-yellow-500">Rp. {{ formatPrice(donation.total) }} </span>
                            terkumpul 
                        </p>
                    </div>
                    <div v-else>
                        <p class="mt-4 text-sm text-gray-500">
                            <span class="font-bold text-yellow-500">Rp. {{ formatPrice(donation.total) }} </span>
                            terkumpul dari
                            <span class="font-bold">Rp. {{ formatPrice(targetDonation) }}</span>
                        </p>

                        <div class="relative pt-1 mt-2">
                            <div class="overflow-hidden h-2 mb-4 text-base flex rounded bg-yellow-200">
                                <div :style="{width: percentage(donation.total, targetDonation) + '%'}"
                                    class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-500">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-if="typeCampaign == campaignType.QURBAN_KAMBING || typeCampaign == campaignType.QURBAN_SAPI || typeCampaign == campaignType.QURBAN_PATUNGAN">
                    <p class="mt-4 text-sm text-gray-500">
                        <span class="font-bold text-yellow-500">Rp. 0 </span> terkumpul 
                    </p>
                </div>
                <div v-else>
                    <p class="mt-4 text-sm text-gray-500">
                        <span class="font-bold text-yellow-500">Rp. 0 </span> terkumpul dari
                        <span class="font-bold">Rp. {{ formatPrice(targetDonation) }}</span>
                    </p>
                    <div class="relative pt-1 mt-2">
                        <div class="overflow-hidden h-2 mb-4 text-xs flex rounded bg-yellow-200">
                            <div :style="{width: percentage(0, targetDonation) + '%'}"
                                class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-500">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3 text-sm">
                <span class="font-bold">{{ donations.length }}</span> Donasi
                <span class="float-right"> <strong>{{ countDay(maximumDate) }}</strong> hari lagi</span>
            </div>

            <div v-if="maxDate(maximumDate) == true">
                <div class="mt-5">
                    <button
                        class="bg-yellow-500 py-1.5 rounded-md shadow-md text-lg w-full uppercase font-bold focus:outline-none opacity-50 cursor-not-allowed">Donasi
                        Ditutup!</button>
                </div>
            </div>
            <div v-else>
                <div class="grid grid-cols-6 gap-4">
                    <div class="mt-5 col-span-5">
                        <div v-if="typeCampaign == campaignType.QURBAN_KAMBING || typeCampaign == campaignType.QURBAN_SAPI || typeCampaign == campaignType.QURBAN_PATUNGAN">
                            <router-link :to="{name: 'qurban.create', params:{
                                slug: slug, 
                                nominal: nominal,
                                qurbanCount: typeCampaign == campaignType.QURBAN_SAPI ? 7 : 1
                            }}">
                                <button
                                    class="bg-yellow-500 py-1.5 rounded-md shadow-md text-lg w-full uppercase font-bold text-white focus:outline-none focus:bg-yellow-600">Qurban
                                    Sekarang!</button>
                            </router-link>
                        </div>
                        <div v-else>
                            <router-link :to="{name: 'donation.create', params:{slug: slug}}">
                                <button
                                    class="bg-yellow-500 py-1.5 rounded-md shadow-md text-lg w-full uppercase font-bold text-white focus:outline-none focus:bg-yellow-600">Donasi
                                    Sekarang!</button>
                            </router-link>
                        </div>
                    </div>
                    
                    <button @click="setShareShowed" class="bg-yellow-500 w-full mt-5 py-1.5 rounded-md">
                        <i class="fa fa-share-alt text-white"></i>
                    </button>
                </div>

            </div>
        </div>
    </div>

</template>

<script>

//hook vuex
import { useStore } from "vuex";
import CampaignTypeEnum from '@/enums/CampaignTypeEnum.js'

export default {
    props: {
        image: String,
        title: String,
        slug: String,
        sumDonation: Array,
        donations: Array,
		targetDonation: String,
		maximumDate: String,
        typeCampaign: String,
        nominal: String
    },
    setup() {
        const campaignType = CampaignTypeEnum

        //store vuex
        const store = useStore();


        function setShareShowed() {
            store.dispatch('campaign/setShareActionSheet', true)
        }

        return {
            campaignType,
            setShareShowed
        }
    }
}

// Script Pixel ViewContent
window.fbq('track', 'ViewContent');

</script>
