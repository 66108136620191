<template>
    <div class="grid grid-cols-10 gap-4 mt-3">
        <div class="col-span-2">
            <div class="w-10 h-10 float-right p-1 bg-gray-100 rounded-full">
                <img :src="avatar" class="rounded-full">
            </div>
        </div>
        <div class="col-span-8">
            <div class="text-sm font-bold">{{ name }}</div>
            <div class="text-gray-400 text-xs italic">{{ created_at }}</div>
            <div class="text-sm mt-2">
                Berdonasi sebesar <span class="font-bold">Rp. {{ formatPrice(amount) }}</span>
            </div>
        </div>
    </div>

    <div class="mt-1 mx-auto w-4/5">
        <div class="text-gray-500 text-xs text-left italic">{{ pray }}</div>
    </div>
    <div v-if="pray != null" class="w-3/5 mx-auto">
        <div class="text-xs text-gray-400 text-center prevent-select">
            <i @click="amin.isAmin == false && storeAmin(id)" class="fa fa-heart p-2" :class="{'text-red-500': amin.isAmin}" aria-hidden="true" :id="id"></i>
            {{amin.count != 0 ? amin.count + ' orang aamiiin' : 'aamiiin'}}
        </div>
    </div>
    <div class="border-t border-gray-200 mb-3 mx-auto w-4/5" :class="pray != null ? 'mt-1' : 'mt-3'"></div>
</template>

<script>
//hook vue
import { reactive } from 'vue'

//hook vuex
import { useStore } from "vuex";

export default {
    props: {
        id: Number,
        avatar: String,
        name: String,
        created_at: String,
        amount: Number,
        pray: String,
        count_amin: Number
    },
    setup(props) {

        //store vuex
        const store = useStore();

        const amin = reactive({
            isAmin: false,
            count: props.count_amin == null ? 0 : props.count_amin
        })

        function aminSet(id) {
            amin.isAmin = true;
            amin.count += 1;
            var el = document.getElementById(id);
            el.classList.add("text-red-600");
        }

        function storeAmin(idAmin) {
            let id = idAmin
            console.log('ID => ', id)
            store.dispatch('campaign/storeDonatursPrayAmin', {id})
                .then((res) => {

                    console.log('aamiiin success')
                    console.log(res)
                    amin.isAmin = true;
                    amin.count += 1;
                    // toast.success('Transaksi Berhasil Dibuat!')

                })
                .catch(error => {
                    console.log(error)
                })
        }

        return {
            amin,
            aminSet,
            storeAmin
        }
    }
}
</script>
