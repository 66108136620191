<template>
    <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">
        <div class="bg-white rounded-xl shadow-md p-3">
            <div class="text-base font-semibold">
                Penggalang Dana
            </div>
            <div class="border-2 border-gray-200 mt-3 mb-2"></div>
            <div class="bg-gray-200 p-3 rounded shadow-md mb-3">
                <div class="grid grid-cols-10 gap-4">
                    <div class="col-span-2">
                        <img :src="avatar" class="w-8 h-8 rounded-full shadow">
                    </div>
                    <div class="col-span-8 text-lg font-bold mt-1">{{ name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        avatar: String,
        name: String
    }
}
</script>
