<template>
  <div class="pb-20 pt-20">
    <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">
      <div v-if="allNews.length > 0">
        <div class="vtl" v-for="(news, index) in allNews" :key="news.id">
          <NewsUpdateItem
            :image="news.user.avatar"
            :title="news.title"
            :desc="news.description"
            :withdraw="news.pencairan"
            :isFirst="index == 0"
            :isLast="index == allNews.length - 1"
            :createdDate="news.created_at"
          />
        </div>
      </div>
      <div v-else>
        <div
          v-for="index in 2"
          :key="index"
          class="
            grid grid-cols-1
            bg-white
            rounded
            shadow-md
            p-3
            text-sm
            mt-4
            mb-4
          "
        >
          <FacebookLoader class="h-24" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//hook vue
import { computed, onMounted } from "vue";

//vuex
import { useStore } from "vuex";

//hook vue router
import { useRoute } from "vue-router";

//vue content loader
import { FacebookLoader } from "vue-content-loader";

import NewsUpdateItem from "@/components/NewsUpdateItem.vue";

export default {
  components: {
    FacebookLoader,
    NewsUpdateItem, // <-- register component FacebooLoader dari Vue Content Loader
  },

  setup() {
    //vue route
    const route = useRoute();

    //store vuex
    const store = useStore();

    //onMounted akan menjalankan action "getCampaign" di module "campaign"
    onMounted(() => {
      store.dispatch("campaign/getAllNews", route.params.id);
    });

    //digunakan untuk get data  state "allNews" di module "campaign"
    const allNews = computed(() => {
      console.log("NEWS ALL ", store.state.campaign.allNews);
      return store.state.campaign.allNews;
    });

    return {
      allNews,
    };
  },
};
</script>

<style>
/* (A) TIMELINE CONTAINER */
.vtl {
  /* (A1) RELATIVE POSITION REQUIRED TO PROPERLY POSITION THE TIMELINE */
  position: relative;

  /* (A2) RESERVE MORE SPACE TO THE LEFT FOR THE TIMELINE */
  padding: 10px 10px 10px 30px;

  /* (A3) OPTIONAL WIDTH RESTRICTION *
  max-width: 400px;*/
}
.vtl,
.vtl * {
  box-sizing: border-box;
}
</style>