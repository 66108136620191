<template>
    <div v-if="campaign.image != undefined" class="pb-5 pt-14">
        <HighlightCard 
            :image="campaign.image"
            :title="campaign.title"
            :slug="campaign.slug"
            :sumDonation="sumDonation"
            :donations="donations"
            :targetDonation="campaign.target_donation"
            :maximumDate="campaign.max_date"
            :typeCampaign="campaign.tipe_campaign"
            :nominal="campaign.nominal_qurban"
        />

        <AuthorCard
            :avatar="user.avatar"
            :name="user.name"
        />

        <StoryCard :story="campaign.description" />

        <NewsCard
            :news="news"
            :campaignId="campaign.id"
        />

        <DonatursCard :donations="donations" />

        <BottomFixedButton
            :scrollY="scroll.y"
            :slug="campaign.slug"
            :maximumDate="campaign.max_date"
            :typeCampaign="campaign.tipe_campaign"
            :nominal="campaign.nominal_qurban"
        />

        <div v-if="isShareShowed">
            <ShareActionSheet 
                :shareUrl="shareUrl"
                :shareTitle="campaign.title"
            />
        </div>

    </div>
</template>

<script>
    //hook vue
    import { computed, onMounted, reactive } from "vue"; // computed dan onMounted

    //hook vuex
    import { useStore } from "vuex";

    //hook vue router
    import { useRoute } from "vue-router";

    import HighlightCard from '@/components/campaign/show/HighlightCard.vue'
    import AuthorCard from '@/components/campaign/show/AuthorCard.vue'
    import StoryCard from '@/components/campaign/show/StoryCard.vue'
    import NewsCard from '@/components/campaign/show/NewsCard.vue'
    import DonatursCard from '@/components/campaign/show/DonatursCard.vue'
    import BottomFixedButton from '@/components/campaign/show/BottomFixedButton.vue'
    import ShareActionSheet from '@/components/campaign/show/ShareActionSheet.vue'

    export default {

        components: {
            HighlightCard,
            AuthorCard,
            StoryCard,
            NewsCard,
            DonatursCard,
            BottomFixedButton,
            ShareActionSheet
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },
        unmounted() {
            window.removeEventListener('scroll', this.handleScroll);
        },

        setup() {
            //vue route
            const route = useRoute();

            //store vuex
            const store = useStore();

            //onMounted akan menjalankan action "getDetailCampaign" di module "campaign"
            onMounted(() => {
                store.dispatch("campaign/getDetailCampaign", route.params.slug);
            });

            let campaign_slug = route.params.slug

            store.dispatch('campaign/storeShowCampaign', {campaign_slug})
            .then(() => {
                console.log('sukses store show campaign')
            })
            .catch(error => {
                console.log(error)
            })

            const scroll = reactive({
                y: window.scrollY
            })

            //digunakan untuk mendapatkan data detail campaign dari state "campaign" di module "campaign" Vuex
            const campaign = computed(() => {
                var judul = store.state.campaign.campaign.title;
                document.title = judul;
                return store.state.campaign.campaign;
            });

            //digunakan untuk mendapatkan data detail user dari state "user" di module "campaign" Vuex
            const user = computed(() => {
                return store.state.campaign.user;
            });

            //digunakan untuk mendapatkan data jumlah donasi state "sumDonation" di module "campaign" Vuex
            const sumDonation = computed(() => {
                return store.state.campaign.sumDonation;
            });

            //digunakan untuk mendapatkan data donation dari state "donations" di module "campaign" Vuex
            const donations = computed(() => {
                return store.state.campaign.donations;
            });

            //digunakan untuk mendapatkan data news dari state "news" di module "campaign" Vuex
            const news = computed(() => {
                return store.state.campaign.news;
            });

            const newsAuthor = computed(() => {
                return store.state.campaign.news.user;
            });

            function handleScroll() {
                scroll.y = window.scrollY
            }

            const isShareShowed = computed(() => {
                return store.state.campaign.isShareActionSheetOpen;
            });

            const shareUrl = computed(() => {
                return window.location.href
            });

            return {
                scroll,
                campaign, // <-- campaign
                user, // <-- user
                sumDonation, // <-- sumDonation
                donations, // <-- donations
                news, // <-- news
                newsAuthor,
                handleScroll,
                isShareShowed,
                shareUrl
            };
        },
    };

</script>
