<template>
    <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">
        <div class="bg-white rounded-xl shadow-md p-3">
            <div class="text-base font-semibold">Kabar terbaru</div>
            <div class="border-2 border-gray-200 mt-3 mb-2"></div>

            <div v-if="news != null">
                <div class="vtl">
                    <CampaignNewsUpdateItem :image="news.user.avatar" :title="news.title" :desc="news.description"
                        :isFirst="true" :isLast="false" :createdDate="news.created_at" />
                    <div class="w-full h-44 absolute -ml-9 bottom-0 fade-in">
                        <router-link :to="{name: 'news.index', params: {id: campaignId}}">
                            <button class="absolute left-1/3 bottom-5 bg-white underline text-yellow-500">Lihat
                                Selengkapnya</button>
                        </router-link>
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="text-xs">
                    Tidak ada kabar terbaru
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CampaignNewsUpdateItem from '@/components/CampaignNewsUpdateItem.vue'

export default {
    props: {
        news: Object,
        campaignId: Number
    },
    components: {
        CampaignNewsUpdateItem
    }
}
</script>
<style>
/* (A) TIMELINE CONTAINER */
.vtl {
    /* (A1) RELATIVE POSITION REQUIRED TO PROPERLY POSITION THE TIMELINE */
    position: relative;

    /* (A2) RESERVE MORE SPACE TO THE LEFT FOR THE TIMELINE */
    padding: 10px 10px 10px 30px;

    /* (A3) OPTIONAL WIDTH RESTRICTION *
max-width: 400px;*/
}

.vtl,
.vtl * {
    box-sizing: border-box;
}

.fade-in {
    background-image: linear-gradient(#FFFFFF00 30%, #FFF 75%);
}
</style>
